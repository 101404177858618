<template>
  <v-card class="pa-3">
    <div class="headerBox">
      <h1>{{ $t("linkDevice.stream") }}</h1>

      <v-text-field :label="$t('linkDevice.search')" prepend-icon="mdi-magnify" class="searchInput" @keyup="search" @click:clear="clearSearch" clearable></v-text-field>

      <div class="buttonContainer">
        <select id="serverSelector" @change="changeServer($event)" v-model="selectedServer" v-if="this.user.permissions.role == 'admin'">
          <option :value="this.servers.live">live server</option>
          <option :value="this.servers.dev">dev server</option>
        </select>
        <router-link :to="{ name: 'koppelApparaat' }">
          <v-btn color="primary">{{ $t("linkDevice.link") }}</v-btn>
        </router-link>
      </div>
    </div>

    <div class="alert alert-info" v-if="notAuthString && this.user.permissions.role == 'admin'">{{notAuthString}}</div>

    <v-data-table
      :headers="headers"
      :items="devices"
      item-key="stream_id"
      hide-default-header
      hide-default-footer
      disable-pagination
      class="devices"
    >

      <template v-slot:header="{ props }">
        <thead class="v-data-table-header">
          <tr>
            <th v-for="(header, index) in props.headers" :key="index" role="columnheader" scope="col" class="text-start sortable">
              <!-- first header -->
              <v-checkbox v-if="index == 0" @change="toggleAllCheckboxes"></v-checkbox>

              <!-- regular header -->
              <span v-if="header.text">{{ header.text }}</span>

              <!-- last header -->
              <router-link 
                :to="`gekoppelde-apparaten/${devices.filter(d => d.checked).map(d => d.stream_id).join('-')}`" 
                v-if="index == (props.headers.length - 1)"
              >
                <v-btn color="primary" :disabled="devices.filter(d => d.checked).length == 0">{{ $t('linkDevice.editAll') }}</v-btn>
              </router-link>
            </th>
          </tr>
        </thead>
      </template>

      <template v-slot:item="{ item }">
        <tr :class="(item.checked) ? 'selected' : ''">
          <td class="hideOnMobile">
            <v-checkbox v-model="item.checked"></v-checkbox>
          </td>
          <td class="text-start">
            <div class="status" :class="item.status" :title="$t(`linkDevice.table.status.${item.status}`)"></div>
          </td>
          <td class="text-start hideOnMobile" v-if="hasAllDevices"> {{ item.user.name }} </td>
          <td class="text-start"> 
            <div class="tableRow">
              <div>
                {{ item.name }} 
              </div>
              <div class="onlyOnMobile textGrey" v-if="hasAllDevices">
                {{ item.user.name }} 
              </div>
            </div>
          </td>
          <td class="text-start hideOnMobile"> {{ item.stream_id }} </td>
          <td class="text-start hideOnMobile"> {{ item.city }} </td>
          <td class="text-start hideOnMobile"> {{ item.tags.join(', ') }} </td>
          <td class="text-start"> 
            <div class="dropdown" :class="{ active: item.showDropdown }">
              <v-btn @click="toggleDropdown(item.stream_id)" v-click-outside="e => toggleDropdown(item.stream_id, false)" >
                {{$t('linkDevice.manage')}}
                <v-icon class="icon">mdi-chevron-down</v-icon>
              </v-btn>
              <ul class="dropdown-menu">
                <li>
                  <router-link :to="`/gekoppelde-apparaten/${item.stream_id}`">{{ $t('linkDevice.dropdown.edit') }}</router-link>
                </li>  
                <li>
                  <a @click="openLogModal(item.stream_id)">{{ $t('linkDevice.dropdown.logs') }}</a>
                </li>
                <li>
                  <a v-if="item.active" @click="askUnlinkConfirmation(item.stream_id)">{{ $t('linkDevice.dropdown.unlink') }}</a>
                  <a v-if="!item.active" :href="`/link-device/stream/${item.stream_id}`">{{ $t('linkDevice.dropdown.link') }}</a>
                </li>
              </ul>  
            </div>
          </td>
        </tr>
      </template>

    </v-data-table>

    <div v-if="!devices.length">{{ $t("linkDevice.no_devices") }}</div>

    <v-dialog v-model="model.unlink" width="500">
      <v-card>
        <v-card-title>
        {{ $t("linkDevice.unlinkTitle") }}
        </v-card-title>

        <v-card-text>
          {{ $t("linkDevice.unlinkDescription") }} 
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="model.unlink = false">
            {{ $t("linkDevice.cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            @click="unlink()"
          >
            {{ $t("linkDevice.unlink") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- extra -->
    <v-dialog v-model="model.logs" width="1450">
      <v-card>
        <v-card-title>
          {{ $t("linkDevice.log.title") }}
        </v-card-title>
        <div class="logModalBody">

          <div class="tab-selector">
            <div class="tab" :class="tab == 'activity' ? 'active' : ''" @click="tab = 'activity'">{{ $t("linkDevice.log.tabs.activity") }}</div>
            <div class="tab" :class="tab == 'click' ? 'active' : ''" @click="tab = 'click'">{{ $t("linkDevice.log.tabs.clicks") }}</div>
          </div>

          <div class="tab-windows">
            <div class="tab" v-if="tab == 'activity'">

              <div class="filters">
                <span class="label">
                  <v-icon>mdi-filter-variant</v-icon>
                  Filters
                </span>
                <v-select :items="onlineTable.years" v-model="onlineTable.selectedYear" @change="changeYear($event)"></v-select>
                <v-select :items="activityCategories" v-model="onlineTable.selectedCategory" @change="changeCategory($event)"></v-select>
              </div>

              <!-- Online Activity -->
              <div class="tableContainer">
                <table id="online">
                  <tr><td v-for="x in onlineTable.labels.x" :key="x" class="label-x">{{x}}</td></tr> <!-- Day label -->
                  <tr v-for="(month, index) in onlineTable.rows" :key="index">
                    <td>{{onlineTable.labels.y[index]}}</td> <!-- Month label -->
                    <td v-for="(day, index) in month" :key="index" :class="{ hidden: !day.valid, active: day.minutes >= 1, highactivity: day.minutes >= 240 }" :data-minutes="day.minutes" class="day">{{day.hours}}</td> 
                  </tr>
                </table>
              </div>

            </div>
            <div class="tab" v-if="tab == 'click'">

              <div class="filters">
                <span class="label">
                  <v-icon>mdi-filter-variant</v-icon>
                  Filters
                </span>
                <v-select :items="chart.filters.months.data" multiple v-model="chart.filters.months.selected" @change="changeChartMonth($event)" item-text="label" item-value="value"></v-select>
              </div>

              <div class="chartContainer">

                <div @click="clickLogsPathBack()" v-if="chart.currentPath != ''" class="backButton">
                  <v-icon>mdi-chevron-left</v-icon>
                  <span>{{chart.currentFolder}}</span>
                </div>

                <div class="chart" @click="clickChart($event)">
                  <!-- Clicks -->
                  <GChart
                    type="PieChart"
                    :data="chart.data"
                    :events="chart.events"
                    :options="chart.options"
                  />
                </div>
              </div>

            </div>
          </div>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="model.logs = false">
              {{ $t("linkDevice.cancel") }}
            </v-btn>
          </v-card-actions>

        </div>
      </v-card>
    </v-dialog>
    
  </v-card>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import { mapGetters } from "vuex";
import { DateTime } from "luxon";
import { GChart } from 'vue-google-charts/legacy';
import { ILLI_API_SERVER_URL, ILLI_STREAM_API_SERVERS } from "../config";
import { EventBus } from '@/utils/eventBus';

export default Vue.extend({
  components: {
    GChart
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
  data: () => ({
    servers: {},
    selectedServer: null,
    selectedId: null,
    headers: [],
    devices: [],
    allDevices: [],
    hasAllDevices: false,
    notAuthString: null,
    model: {
      logs: false,
      unlink: false,
      delete: false,
    },
    tab: 'activity',
    activityCategories: [],
    onlineTable: {
      years: [],
      labels: {
        x: [null,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
        y: ['januari','februari','maart','april','mei','juni','juli','augustus','september','oktober','november','december']
      },
      rows: [],
      selectedYear: null,
      selectedCategory: null,
    },
    onlineData: [],
    activityData: [],
    clickData: [],
    chart: {
      filters: {
        months: {
          data: [],
          selected: []
        }
      },
      currentPath: '',
      currentFolder: '',
      data: [],
      options: {
        height: 600
      },
    } 
  }),
  created() {
    this.servers = ILLI_STREAM_API_SERVERS;
    this.selectedServer = localStorage.getItem('stream-server');

    this.getOnlineDevices().then((onlineDevices) => {
      this.loadAccounts(onlineDevices);
    })
  },
  methods: {
    getOnlineDevices(){
      return new Promise((resolve) => {
        axios
          .get(`${localStorage.getItem('stream-server')}/onlineDevices`)
          .then((result) => {
            const notAuthDevices = result.data.connected.length - result.data.loggedIn.length;
            this.notAuthString = (notAuthDevices == 0) ? null :  
              (notAuthDevices != 1) 
                ? `Momenteel zijn er ${notAuthDevices} apparaten in het loginscherm.` 
                : `Momenteel is er 1 apparaat in het loginscherm.`;
            resolve(result.data.loggedIn);
          })
          .catch((err) => {
            console.error({err});
            resolve([]);
          })
      });
    },
    loadAccounts(onlineDevices) {
      axios
        .get(`${ILLI_API_SERVER_URL}/stream/accounts`)
        .then((result) => {

          const devices = result.data.accounts.filter((account) => {
            const isParent = result.data.accounts.some((a) => a.parent_stream_id == account.stream_id);
            return !isParent;
          })

          this.setHeaders();

          this.allDevices = devices.map((device) => {
            const tooManyTags = device.tags.length > 5;
            const amountOftags = Math.min(device.tags.length, 5);
            const online = onlineDevices.some(e => e == device.stream_id);

            const mappedDevice = {
              ...device,
              checked: false,
              online: online,
              tags: device.tags.slice(0, amountOftags).map((t) => t.tag),
              showDropdown: false,
              status: (online && device.active) ? 'online' : (device.active) ? 'offline' : 'unlinked'
            };

            if (tooManyTags) mappedDevice.tags.push("...");
            return mappedDevice;
          });

          this.devices = this.allDevices;

        });
    },

    loadOnlineLogs(id) {
      console.log('load online logs', id);
      axios
        .get(`${ILLI_API_SERVER_URL}/stream/${id}/onlineLog`)
        .then((results) => {

          const years = results.data.online.map((timestamp) => {
            const luxon = DateTime.fromSeconds(parseInt(timestamp) / 1000);
            return luxon.toFormat('yyyy');
          })
          this.onlineTable.years = [...new Set(years)];

          const datesOnline = results.data.online.map((timestamp) => {
            const luxon = DateTime.fromSeconds(parseInt(timestamp) / 1000);
            return luxon.toFormat('d-M-yyyy');
          })

          const countMinutes = {};
          for (const date of datesOnline) {
            countMinutes[date] = countMinutes[date] ? countMinutes[date] + 5 : 5; // for each record, count 5 minutes
          }

          this.onlineData = countMinutes;

          this.onlineTable.selectedYear = DateTime.now().toFormat('yyyy');
          this.changeCategory('online');
        });
    },

    loadActivityLogs(id) {
      console.log('load activity logs', id);
      axios
        .get(`${ILLI_API_SERVER_URL}/stream/${id}/activityLog`)
        .then((results) => {

          const translateMap = new Map([
            ['dayprogram', 'dagprogramma']
          ])

          function getKey(type, subtype){
            const key = [type, subtype].filter(e => e).join(': ');
            return ((translateMap.has(key)) ? translateMap.get(key) : key);
          }

          this.activityCategories = ['online', ...new Set(
            results.data.activity.map(a => getKey(a.type, a.subtype))
          )]

          this.onlineTable.selectedCategory = this.activityCategories[0];

          this.activityData = results.data.activity.reduce((all, crr) => {
            const date = DateTime.fromISO(crr.date).toFormat('d-M-yyyy');
            const key = getKey(crr.type, crr.subtype);
            const durationInMin = crr.duration / 60;
            if (all[key]) all[key][date] = durationInMin;
            else{
              all[key] = {}
              all[key][date] = durationInMin;
            } 
            return all;
          }, {});

        });
    },

    loadClickLogs(id) {
      console.log('load click logs', id);
      axios
        .get(`${ILLI_API_SERVER_URL}/stream/${id}/clickLog`)
        .then((results) => {

          console.log('res click logs');
          console.warn(results.data.click);

          this.clickData = results.data.click.map((click) => { 
            const luxon = DateTime.fromSeconds(parseInt(click.timestamp) / 1000);
            return {
              path: click.display,
              month: parseInt(luxon.toFormat('M')),
              year: parseInt(luxon.toFormat('yyyy'))
            }
          })

          const data = this.clickData.reduce((all, crr) => {
            const value = `${crr.month}-${crr.year}`;
            if (all.filter(e => e.value == value).length == 0){
              const monthLabel = DateTime.fromFormat(`${crr.month}`, 'M').setLocale('NL').toFormat('MMM');
              all.push({value: value, label: `${monthLabel} ${crr.year}`});
            }
            return all;
          }, []);
          
          this.chart.filters.months.data = data;
          this.chart.filters.months.selected = this.chart.filters.months.data.map(e => e.value);

          this.loadClickLogsPath();
        });
    },

    loadClickLogsPath(){
      const path = (this.chart.currentPath == '') ? '' : `${this.chart.currentPath}/`;

      const data = this.clickData
        .filter(e => e.path.startsWith(path))
        .filter(e => this.chart.filters.months.selected.includes(`${e.month}-${e.year}`))
        .reduce((all,crr) => {
          const folder = crr.path.replace(path, '').split('/')[0];
          if (all[folder]) all[folder] += 1;
          else all[folder] = 1;
          return all;
        }, {});

      this.chart.data = [[['folder'],['kliks']]].concat(Object.entries(data));

      this.chart.currentFolder = this.chart.currentPath.split('/').slice(-1)[0];
    },

    clickLogsPathBack(){      
      this.chart.currentPath = this.chart.currentPath.split('/').slice(0, -1).join('/');
      this.loadClickLogsPath();
    },

    clickChart(e){
      try {
        const color = e.srcElement.getAttribute('fill');
        const folder = document.querySelectorAll(`circle[fill="${color}"]`)[0].parentElement.getAttribute('column-id');
        const newPath = (this.chart.currentPath == '') ? folder : `${this.chart.currentPath}/${folder}`;
        const hasChildren = (this.clickData.filter(e => e.path.startsWith(`${newPath}/`) && e.path != newPath).length > 0)

        if (hasChildren){
          this.chart.currentPath = newPath;
          this.loadClickLogsPath();
        }
      } catch (error) {
        console.log('err no one cares');
        console.error({error});
      }
    },
    
    changeChartMonth(month){
      console.log('change month', month);
      this.loadClickLogsPath()
    },

    openLogModal(id){
      this.selectedId = id;
      this.loadOnlineLogs(id);
      this.loadActivityLogs(id);
      this.loadClickLogs(id);
      this.model.logs = true;
    },

    changeYear(year){
      let data = []

      for (let month = 1; month <= 12; month++) {
        const monthData = [];

        for (let day = 1; day <= 31; day++) {
          const totalMinutes = this.minutesOnline[`${day}-${month}-${year}`] || 0;
          const hours = parseInt(totalMinutes / 60);
          const minutes = totalMinutes % 60;
          const minuteStr = `${Math.floor(minutes)}`.padStart(2, '0');  
          const hourStr = `${hours}:${minuteStr}`;
          const valid = DateTime.fromObject({day, month, year}).isValid;
          monthData.push({day, month, year, hours: hourStr, minutes: totalMinutes, valid});
        }
        
        data.push(monthData);
      }

      this.onlineTable.rows = data;
    },

    changeCategory(category){
      console.warn({category});

      switch (category) {
        case 'online': {
          
          console.warn('set table', this.onlineData);

          this.minutesOnline = this.onlineData;
          this.changeYear(this.onlineTable.selectedYear); 

          break;

        }
        default: {

          console.warn('set table', this.activityData[category]);

          this.minutesOnline = this.activityData[category];
          this.changeYear(this.onlineTable.selectedYear); 

          break;
        }
      }
    },

    askUnlinkConfirmation(id){
      this.selectedId = id;
      this.model.unlink = true;
    },

    unlink() {
      console.log("unlink", this.selectedId);
      axios
        .delete(`${ILLI_API_SERVER_URL}/stream/account/${this.selectedId}/link`)
        .then(() => {
          EventBus.$emit('refresh');
        });
    },

    setHeaders() {
      const userHeader = this.hasAllDevices
        ? { text: this.$t("linkDevice.table.user"), value: "user" }
        : undefined;

      this.headers = [
        { text: "", value: "checkbox" },
        { text: "", value: "online" },
        userHeader,
        { text: this.$t("linkDevice.table.name"), value: "name" },
        { text: this.$t("linkDevice.table.id"), value: "stream_id" },
        { text: this.$t("linkDevice.table.city"), value: "city" },
        { text: this.$t("linkDevice.table.tags"), value: "tags" },
        { text: "", value: "manage" },
      ].filter((e) => e !== undefined);
    },

    toggleDropdown(id, value = null){
      if (value === null) this.devices.filter(e => e.stream_id != id).map(e => e.showDropdown = false); // all others inactive
      this.devices.filter(e => e.stream_id == id).map(e => e.showDropdown = (value === null) ? !e.showDropdown : value); // toggle
    },

    changeServer(event){
      localStorage.setItem('stream-server', event.target.value)
      EventBus.$emit('refresh');
    },

    toggleAllCheckboxes(){
      const allChecked = (this.devices.filter(e => !e.checked).length == 0);
      this.devices = this.devices.map(e => e = {...e, checked: !allChecked});
    },

    search(e){
      const query = e.target.value.toLowerCase();
      this.devices = this.allDevices.filter(device => JSON.stringify(device).toLowerCase().includes(query));
    },

    clearSearch(){
      this.devices = this.allDevices;
    }

  },
});
</script>

<style>

.searchInput .v-input__slot{
  margin-bottom: 0;
}

.searchInput .v-text-field__details{
  display: none;
}

.devices table{
  padding-bottom: 150px;
}

table .v-input--checkbox{
  margin: 0;
  padding: 0;
}

table .v-messages{
  display: none;
}

table .v-input__slot{
  margin: 0;
}

.table v-application--is-ltr .v-messages{
  display: none;
}

@media (max-width: 800px){
  .hideOnMobile{ display: none; }
}

</style>

<style scoped>

table{
  padding-bottom: 200px;
}

.buttonContainer{
  display: flex;
  gap: 10px;
}

.buttonContainer .v-btn {
  padding: 0;
}

.headerBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 25px;
}

.searchInput{
  max-width: 300px!important;
  margin: 0 15px!important;
  padding: 0!important;
}

.devices {
  width: 100%;
}

.devices td,
.devices th {
  text-align: left;
  padding: 5px;
}

.devices th:last-child,
.devices td:last-child {
  text-align: right!important;
}

.v-application--is-ltr .v-messages{
  display: none;
}

.selected{
  background-color: #d8ecff!important;
}

.status {
  width: 15px;
  height: 15px;
  border-radius: 15px;
}
.online {
  background: green;
}
.offline {
  background: rgb(158, 0, 0);
}
.unlinked {
  background: rgb(83, 83, 83);
}

.dropdown{
  position: relative;
}

.dropdown.active .icon{
  transform: rotate(180deg);
}
.dropdown.active .dropdown-menu{
  display: block;
}

.dropdown .dropdown-menu{
  list-style: none;
  position: absolute;
  z-index: 20;
  background: #fff;
  padding: 10px;
  right: 0;
  margin: 0;
  border: 1px solid darkgrey;
  display: none;
}

.dropdown .dropdown-menu a{
  color: black;
  text-decoration: none;
  font-size: 0.875rem;
  text-align: left;
  display: block;
  padding: 4px 10px;
}

.dropdown .dropdown-menu a:hover{
  background: #f1f1f1;
}

.v-data-table__wrapper{
  overflow: visible!important;
}

.logModalBody{
  padding: 0px 24px 5px;
}

.tab-selector{
  display: flex;
  gap: 20px;
}

.tab-selector .tab{
  background: #e0e0e0;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  color: rgb(109, 109, 109);
}

.tab-selector .tab.active{
  font-weight: 500;
  color: rgb(19, 19, 19);
}

.filters{
  display: flex;
  gap: 20px;
  padding: 0 20px;
  background: #f1f1f1;
  margin: 10px 0;
  align-items: center;
  border-radius: 10px;
}

.filters .label{
  margin-right: 10px;
}

#online td.label-x{
  text-align: center;
}

#online td.day{
  background: rgb(204, 204, 204);
  color: #ffffff36;
  width: 40px;
  height: 40px;
  text-align: center;
}

#online td.active{
  background: #70aae4;
  color: #ffffffd9;
}

#online td.highactivity{
  background: #1976d2;
}

#online td.hidden{
  display: none;
}

.tableContainer{
  max-width: 100vw;
  overflow-x: auto;
}

#serverSelector{
  border: 1px solid black;
  appearance: button;
  -webkit-appearance: button;
}

.backButton{ font-weight: 500; cursor: pointer; }

.chart svg > g > g:last-child { pointer-events: none }
.chart svg g path,
.chart svg g ellipse{ cursor: pointer; }
.chart svg g text{ pointer-events: none; }

.onlyOnMobile{ display: none; }
.tableRow{ display: flex; }
.textGrey{ color: grey; }

@media (max-width: 1000px){
  .headerBox{ flex-wrap: wrap; }
}

@media (max-width: 800px){
  .devices thead{ display: none; }
  .onlyOnMobile{ display: block; }
  .searchInput{ max-width: 100%!important; width: 100%; }
  .tableRow{ flex-direction: column; }
  .buttonContainer{ flex-direction: column; flex: 1; }
  .buttonContainer .v-btn{ width: 100%; }
  #serverSelector{ margin-top: 10px; flex: 1; }
}

</style>